<template>
  <div
    class="qr-default-template"
    v-if="qrCodeDefaultTemplate"
    v-html="qrCodeDefaultTemplate"
  ></div>
  <pre v-if="qrCodeRead">{{ qrCodeRead }}</pre>
</template>

<script>
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";

export default {
  name: "QRRead",
  data() {
    return {
      qrCodeRead: null,
      qrCodeDefaultTemplate: null
    };
  },
  created() {
    this.readQRCode();
  },
  computed: {
    qrCode() {
      const { code } = this.$route.params;
      return code;
    }
  },
  methods: {
    readQRCode() {
      httpServiceAuth
        .get(`${apiEndpoints.company.qrCode}/read/${this.qrCode}`)
        .then(response => {
          if (response && response.data.data) {
            this.qrCodeRead = response.data;
            this.qrCodeDefaultTemplate = null;
          } else {
            this.qrCodeRead = null;
            this.qrCodeDefaultTemplate = response.data;
          }
        });
    }
  }
};
</script>
